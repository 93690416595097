@import url("https://fonts.googleapis.com/css?family=Montserrat:800&display=swap");
@import url("https://fonts.googleapis.com/css?family=Lato:400,700,900&display=swap");
@import url("https://cdnjs.cloudflare.com/ajax/libs/normalize/8.0.1/normalize.min.css");
@import "styles/themes";

* {
  box-sizing: border-box;
}

body {
  font: 16px/1.625 $font-family-base;
  color: $color-font;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #052439;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: bottom -130px center, bottom center;
}

a,
button {
  outline: 0;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
  padding: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: $font-weight-bolder;
  font-family: $font-family-secondary;
}

form {
  outline: 0;
}
