// Typography //
$font-size-sm: 12px;
$font-size-base: 14px;
$font-size-lg: 20px;
$font-weight-base: 400;
$font-weight-bold: 700;
$font-weight-bolder: 900;
$font-family-base: "Lato", "Arial", "Helvetica Neue", "Helvetica", sans-serif;
$font-family-secondary: "Montserrat", sans-serif;

// Color //
$color-white: #fff;
$color-deliverr: #5a69dd;
$color-font: #2d2d2d;
$color-error: rgb(252, 60, 60);
$color-border: rgb(215, 220, 230);

$color-blue: rgb(45, 140, 237);
$color-blue-disabled: rgb(158, 192, 226);
$color-gray-darker: #515969;
$color-gray-dark: #a2a7b1;
$color-gray: #edeff3;

// Spacing //
$content-padding-bottom: 40px;
$content-padding: 60px;
$content-padding-sm: 20px;

// Input //
$input-height-sm: 40px;
$input-height: 44px;

// Misc //
$border-radius: 4px;
$border-radius-sm: 2px;
$transition-speed-base: 0.5s ease-in-out;

// Breakpoints //
$breakpoints: (
  sm: "480px",
  md: "768px",
  lg: "1280px",
);

@mixin breakpoint($size: "sm", $width: "null") {
  @if $size == "custom" {
    @media (max-width: $width) {
      @content;
    }
  } @else {
    @media (max-width: map-get($map: $breakpoints, $key: $size)) {
      @content;
    }
  }
}
